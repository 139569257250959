<template>
  <BasicModal
    :action="submitForm"
    :close-on-action="false"
    action-title="Submit idea"
    :show-action-button="!form.isSubmitted"
    :show-cancel-button="!form.isSubmitted"
    :disable-action-button="form.isLoading || !isFormValid"
  >
    <template v-if="!form.isSubmitted">
      <h5>Suggest a Template, Trigger, or Action</h5>
      <p class="mb-6">
        Want a Trigger or Action that isn’t available yet? Have other feedback on
        how we can make Dispatch more useful? Let us know!
      </p>
      <FormSubmitErrors :formName="formName" />
      <FormInput :formName="formName" fieldName="suggestion" ref="focusElement" />
      <LoadingOverlay v-if="form.isLoading" />
    </template>
    <template v-else>
      <div class="success-container">
        <img src="@/assets/images/planet.png" />
        <h4>Thank you!</h4>
        <p>
          We’ve received your feedback and are sharing it with our team.
        </p>
      </div>
    </template>
  </BasicModal>
</template>

<script>

  import useForm from '@/composables/useForm'
  import useRecaptcha from '@/composables/useRecaptcha'

  import FormInput from '@/components/forms/FormInput.vue'
  import BasicModal from '@/components/modals/_BasicModal.vue'
  import LoadingOverlay from '@/components/utils/LoadingOverlay.vue'
  import FormSubmitErrors from '@/components/forms/FormSubmitErrors.vue'

  export default {
    components: {
      FormInput,
      BasicModal,
      LoadingOverlay,
      FormSubmitErrors,
    },
    setup() {

      // data
      const formName = 'suggestAPatchForm'

      // composables
      const { form, isFormValid, focusElement } = useForm({ formName })
      const { setRecaptchaToken } = useRecaptcha({ formName, action: 'suggest_a_patch' })

      return {
        form,
        formName,
        isFormValid,
        focusElement,
        setRecaptchaToken,
      }

    },
    methods: {
      submitForm() {
        return this.setRecaptchaToken()
          .then(() => {
            this.$store.dispatch('forms/SUBMIT_FORM', this.formName)
          })
      },
    },
  }

</script>

<style lang="stylus" scoped>

  .success-container
    @apply flex
    @apply flex-col
    @apply items-center

</style>
